import React, { ReactElement, useCallback, useMemo } from 'react';
import { Col, Row, Space, Table, Button, Typography, message } from 'antd';
import { i18n } from '@amplement/backend-connector';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import {
    ThirdPartyConfigurationDb,
    useTestPostThirdPartyConfigurationById,
    useDeleteThirdPartyConfiguration,
    useThirdPartyConfiguration
} from 'hooks/useThirdPartyConfiguration';
import Link from 'components/Shared/Common/Link';
import { getUrl } from 'utils/url';
import ThirdPartyIcon from 'components/Shared/Common/ThirdPartyIcon';

const { Title } = Typography;
const { Column } = Table;

const FullWidthCol = styled(Col)`
  display: flex;
  flex-flow: column;
  width: 100%;`

const DisableIcon = styled(CloseOutlined)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.error};
`;

const EnableIcon = styled(CheckOutlined)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.success};
`;

type ThirdPartyConfigurationProps = {
    isAdmin?: boolean;
} & WrappedComponentProps;

const ThirdPartyConfiguration = ({intl, isAdmin = false}: ThirdPartyConfigurationProps): ReactElement => {
    const translate = useMemo(() => ({
        new: intl.formatMessage({ id: 'global.button.new' }),
        category: intl.formatMessage({ id: 'thirdPartyConfigurations.label.category' }),
        type: intl.formatMessage({ id: 'thirdPartyConfigurations.label.type' }),
        status: intl.formatMessage({ id: 'thirdPartyConfigurations.label.status' }),
        createdAt: intl.formatMessage({ id: 'global.date.createdAt' }),
        updatedAt: intl.formatMessage({ id: 'global.date.updatedAt' }),
        edit: intl.formatMessage({ id: 'global.button.edit' }),
        delete: intl.formatMessage({ id: 'global.button.remove' }),
        test: intl.formatMessage({ id: 'global.button.test' }),
        isCompanyLevel: intl.formatMessage({ id: 'thirdPartyConfigurations.label.isCompanyLevel' }),
        action: intl.formatMessage({ id: 'global.actions' }),
        noData: intl.formatMessage({ id: 'thirdPartyConfigurations.label.noConfiguration' }),
        title: intl.formatMessage({ id: 'thirdPartyConfigurations.title.list' }),
        success: intl.formatMessage({ id: 'thirdPartyConfigurations.success' }),
        error: intl.formatMessage({ id: 'thirdPartyConfigurations.error' }),
    }), [intl]);

    const handleSuccess = useCallback(() => {
        message.success({
            key: 'thirdPartyConfigurationActionSuccess',
            content: translate.success,
            duration: 3,
        });
    }, [translate]);

    const onError = useCallback((error: any) => {
        const { response } = error;
        message.error({
            key: 'thirdPartyConfigurationActionError',
            content: response?.data?.message || error?.message || translate.error,
            duration: 3,
        });
    }, [translate]);
   const { isLoading, data } = useThirdPartyConfiguration();
    const { mutate: remove, isLoading: deleteIsLoading } = useDeleteThirdPartyConfiguration({ onSuccess: handleSuccess, onError});
    const { mutate: check, isLoading: checkIsLoading } = useTestPostThirdPartyConfigurationById({ onSuccess: handleSuccess, onError});

    return (<FullWidthCol >
        <Space direction="vertical">
            <Row>
                <Title level={3}>
                    {translate.title}
                </Title>
            </Row>
        <Row>
        <Table<ThirdPartyConfigurationDb> dataSource={data} tableLayout='auto' style={{width: '100%'}} size="small" pagination={{ position: ['none', 'none'] }} loading={isLoading} locale={{ emptyText: translate.noData }} >
            <Column title={translate.category} dataIndex="category" key="category" />
            <Column title={translate.type} dataIndex="type" key="type" render={(type) => (<ThirdPartyIcon name={type} style={{'font-size': "2em"}} />)} />
            <Column title={translate.status} dataIndex="enabled" key="enabled" render={(enabled) => (enabled ? <EnableIcon /> : <DisableIcon />)} />
            {isAdmin ? (
                <Column title={translate.isCompanyLevel} dataIndex="isCompanyConfiguration" key="isCompanyConfiguration" render={(isCompanyConfiguration) => (isCompanyConfiguration ? <EnableIcon /> : <DisableIcon />)} />
            )  : null}
            <Column title={translate.createdAt} dataIndex="createdAt" key="createdAt" render={(createdAt: string) => (<>{i18n.formatDate(new Date(createdAt))} {i18n.formatTime(new Date(createdAt))}</>)} />
            <Column title={translate.updatedAt} dataIndex="updatedAt" key="updatedAt" render={(updatedAt: string) => (<>{i18n.formatDate(new Date(updatedAt))} {i18n.formatTime(new Date(updatedAt))}</>)} />
            <Column title={translate.action} dataIndex="id" key="id" render={(id: string) => (<Row><Space>
                <Button color='primary' size={"small"} loading={checkIsLoading} onClick={() => check(id)} icon={<ReloadOutlined />}>{translate.test}</Button>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link href={getUrl('thirdPartyConfiguration', { id })}><Button size={'small'} icon={<EditOutlined />}>{translate.edit}</Button></Link>
                <Button size={'small'} danger loading={deleteIsLoading} onClick={() => remove(id)} icon={<DeleteOutlined />}>{translate.delete}</Button>
            </Space>
            </Row>)} />
        </Table>
        </Row>
        <Row justify="end">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link href={getUrl('thirdPartyConfiguration')}>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => {}}
                    type="primary"
                >{translate.new}</Button>
            </Link>
        </Row>
        </Space>
        </FullWidthCol>);
}

export default injectIntl(ThirdPartyConfiguration);