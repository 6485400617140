import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';
import { injectIntl } from 'react-intl';

import BrandLogo from 'components/Shared/Company/BrandLogo/container';
import AvatarList from 'components/Shared/Call/AvatarList';
import Rating from 'components/Shared/Rating';

const { Text } = Typography;

const StyleRoomErrorLayout = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${props => props.theme.bgRoom};
`;

const StyleRoomErrorContent = styled.div`
    padding: 20px;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    max-width: 600px;
    width: 100%;
    margin: 0px auto;
    overflow: auto;
    flex-direction: column;
    .members{
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-avatar{
            border: 2px solid ${props => props.theme.bgRoom};
        }
        .text{
            margin-top: 10px;
            font-size: 16px;
            color: ${({ theme }) => rgba(theme.fixedWhite, 0.8)};
        }    
        .title{
            margin-top: 50px;
            font-size: 32px;
            color: ${({ theme }) => theme.fixedWhite};
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            line-height: 1.5;
        }    
    }
    .logo{
        margin: 0 auto;
        justify-content: center;
    }
    .button{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .btn {
            margin-bottom: 10px;
        }
        .text{
            color: ${({ theme }) => rgba(theme.fixedWhite, 0.6)};
        }
    }
    .actions{
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
    }
    .logo{
        display: flex;
        height: 50px;
        max-width: 200px;
        align-items: center;
        margin-bottom: 20px;
        img{
            display: flex;
            max-height: 100%;
            align-items: center;
            max-width: 100%;
        }
    }
`;

const RoomFullscreenMessage = ({
    className = 'error-lock active',
    actions = null,
    title = null,
    header,
    content = '',
    callButton = null,
    contactButton = null,
    duration = null,
    members = null,
    isShowStars
}) => (
    <StyleRoomErrorLayout className={className}>
        <StyleRoomErrorContent>
            {!header && <div className="logo"><BrandLogo size="lg" type="shortLogo" /></div>}
            <div className="members">
                {isShowStars &&  (
                    <Rating />
                )}
                {members && (
                    <AvatarList
                        members={members}
                        maxCount={4}
                        hasMore
                        size="xl"
                    />
                )}
                {title && <div className="text">{title}</div>}
                {header}
                <h2 className="title">{content}</h2>
                {duration && (
                    <Text code>
                        {duration}
                    </Text>
                )}
            </div>
            {contactButton && <div className="button">{contactButton}</div>}
            <div className="actions">
                {callButton}
                {actions && <div className="button">{actions}</div>}
            </div>
        </StyleRoomErrorContent>
    </StyleRoomErrorLayout>
);

RoomFullscreenMessage.propTypes = {
    className: PropTypes.string,
    callButton: PropTypes.element,
    contactButton: PropTypes.element,
    members: PropTypes.array,
    duration: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    actions: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default injectIntl(RoomFullscreenMessage);
