import React, { ReactElement, useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Field } from 'react-final-form';
import { Alert } from 'antd';

import { LabelInput, LabelPassword } from 'components/Shared/Forms';
import { composeValidators } from 'utils/formValidator';
import injectValidators from 'components/Shared/Forms/injectValidators';

type SalesforceFormProps = {
    validators: any;
    isEditing?: boolean;
} & WrappedComponentProps;

const SalesforceForm = ({intl, validators, isEditing = false}: SalesforceFormProps) : ReactElement => {
    const translate = useMemo(() => ({
        domain: intl.formatMessage({id: 'thirdPartyConfigurations.label.domain'}),
        domainPlaceholder: intl.formatMessage({id: 'thirdPartyConfigurations.input.domain'}),
        login: intl.formatMessage({id: 'thirdPartyConfigurations.label.login'}),
        loginPlaceholder: intl.formatMessage({id: 'thirdPartyConfigurations.input.login'}),
        password: intl.formatMessage({id: 'settings.menu.password'}),
        securityToken: intl.formatMessage({id: 'thirdPartyConfigurations.label.securityToken'}),
        editingMessage: intl.formatMessage({id: 'thirdPartyConfigurations.editionNotice'}),
    }), [intl]);

    const { required, domain } = validators;
    return (<>
        <Field
            label={translate.domain}
            name={`settings.domain`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={LabelInput}
            placeholder={translate.domainPlaceholder}
            validate={composeValidators(required, domain)}
        />
        <Field
            label={translate.login}
            name={`settings.credentials.login`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={LabelInput}
            placeholder={translate.loginPlaceholder}
            validate={required}
        />
        {isEditing ? (<Alert message={translate.editingMessage} />): null}

        <Field
            label={translate.password}
            name={`settings.credentials.password`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={LabelPassword}
            placeholder={translate.password}
            validate={!isEditing ? required : null}
        />
        <Field
            label={translate.securityToken}
            name={`settings.credentials.securityToken`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={LabelPassword}
        />
    </>);
};

export default injectValidators(injectIntl(SalesforceForm));