import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apis } from '@amplement/backend-connector';
import { AxiosError, AxiosResponse } from 'axios';

export type ThirdPartyConfigurationType = 'salesforce';

export type ThirdPartyConfigurationDb = {
    id: string;
    enabled: boolean;
    isCompanyConfiguration: boolean;
    category: string;
    createdAt: string;
    type: string;
    updatedAt: string;
};

export type ThirdPartyConfigurationSalesforceSettings = {
    domain: string;
    credentials: {
        login: string;
        password?: string;
        securityToken?: string;
    }
};

export type ThirdPartyConfigurationPostBody = {
    isCompanyConfiguration?: boolean;
    enabled?: boolean;
    type: ThirdPartyConfigurationType;
    settings: ThirdPartyConfigurationSalesforceSettings;
};

export type ThirdPartyConfigurationPatchBody = {
    enabled?: boolean;
    type: ThirdPartyConfigurationType;
    settings: ThirdPartyConfigurationSalesforceSettings;
};

type ThirdPartyConfigurationParams = {
    onSuccess?: (response: any) => void
    onError?: (error: AxiosError) => void
}

export const useThirdPartyConfiguration = ()=> {
 return useQuery(['thirdPartyConfigurations'], async (): Promise<ThirdPartyConfigurationDb[]> => {
     const response = await apis.thirdPartyConfigurations.$getThirdPartyConfigurations();
     return response.data || [];
 })
}

export const useThirdPartyConfigurationById = ({ onSuccess, onError }: ThirdPartyConfigurationParams = {})=> {
    const queryClient = useQueryClient();
    return useMutation(
        (id: string) => apis.thirdPartyConfigurations.$getOneThirdPartyConfiguration(id),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('thirdPartyConfigurations');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
}

export const usePostThirdPartyConfiguration = ({ onSuccess, onError }: ThirdPartyConfigurationParams = {})=> {
    const queryClient = useQueryClient();
    return useMutation(
        (payload: ThirdPartyConfigurationPostBody) => apis.thirdPartyConfigurations.$postThirdPartyConfiguration(payload),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('thirdPartyConfigurations');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
};

export const useTestPostThirdPartyConfigurationByPayload = ({onSuccess, onError}: ThirdPartyConfigurationParams)=> {
    return useMutation(
        (payload: ThirdPartyConfigurationPostBody) => apis.thirdPartyConfigurations.$testThirdPartyConfigurationByPayload(payload),
        {
            onSuccess: (response: AxiosResponse) => {
                if (onError && response?.data?.success === false) {
                    onError(response.data);
                } else if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
};

export const useTestPostThirdPartyConfigurationById = ({onSuccess, onError}: ThirdPartyConfigurationParams)=> {
    return useMutation(
        (id: string) => apis.thirdPartyConfigurations.$testThirdPartyConfigurationById(id),
        {
            onSuccess: (response: AxiosResponse) => {
                if (onError && response?.data?.success === false) {
                    onError(response.data);
                } else if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
};

export const usePatchThirdPartyConfiguration = ({ onSuccess, onError }: ThirdPartyConfigurationParams = {})=> {
    const queryClient = useQueryClient();

    return useMutation(
        (data: {id: string, payload: ThirdPartyConfigurationPatchBody}) => apis.thirdPartyConfigurations.$patchThirdPartyConfiguration(data.id, data.payload),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('thirdPartyConfigurations');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
};

export const useDeleteThirdPartyConfiguration = ({ onSuccess, onError }: ThirdPartyConfigurationParams = {})=> {
    const queryClient = useQueryClient();

    return useMutation(
        (id: string) => apis.thirdPartyConfigurations.$deleteThirdPartyConfiguration(id),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries('thirdPartyConfigurations');

                if (onSuccess) {
                    onSuccess(response);
                }
            },
            onError,
        });
};