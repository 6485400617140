import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Field, Form as FinalForm } from 'react-final-form';
import styled from 'styled-components';
import { Form, Button, Alert, Space, Row, Divider } from 'antd';
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';

import injectValidators from 'components/Shared/Forms/injectValidators';
import {
    LabelSelect,
    LabelTogglebutton
} from 'components/Shared/Forms';
import SalesforceForm
    from 'components/Settings/ThirdPartyConfiguration/ThirdPartyConfigurationsAppForms/salesforceForm';
import {
    useTestPostThirdPartyConfigurationByPayload
} from 'hooks/useThirdPartyConfiguration';

const StyledForm = styled(Form)`
    width: 100%;
    max-width: 650px;
`;

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
)

type ThirdPartyConfigurationFormProps = {
    initialValues?: any,
    validators: any,
    onSubmit?: () => void,
    isLoading?: boolean,
    isSubmitDisabled?: boolean,
    error?: Error,
    isEditing?: boolean,
    isAdmin: boolean,
} & WrappedComponentProps;

const ThirdPartyConfigurationForm = ({intl, isAdmin, initialValues = {}, validators, onSubmit = () => {}, isLoading = false, isSubmitDisabled = false, error, isEditing = false}: ThirdPartyConfigurationFormProps): ReactElement => {
    const [checkSuccess, setCheckSuccess] = useState('');
    const [checkError, setCheckError] = useState('');
    const translate = useMemo(() => ({
        type: intl.formatMessage({ id: 'thirdPartyConfigurations.label.type' }),
        enabled: intl.formatMessage({ id: 'thirdPartyConfigurations.input.enabled' }),
        typePlaceholder: intl.formatMessage({ id: 'thirdPartyConfigurations.input.type' }),
        isCompanyLevel: intl.formatMessage({ id: 'thirdPartyConfigurations.input.companyLevel' }),
        submit: intl.formatMessage({ id: 'global.button.save' }),
        success: intl.formatMessage({ id: 'thirdPartyConfigurations.success' }),
        error: intl.formatMessage({ id: 'thirdPartyConfigurations.error' }),
        checkResult: intl.formatMessage({ id: 'thirdPartyConfigurations.label.checkResult' }),
        checkButton: intl.formatMessage({ id: 'thirdPartyConfigurations.button.checkConnection' }),
        crmMessage: intl.formatMessage({ id: 'thirdPartyConfigurations.message.crm' }),
    }), [ intl]);
    const typeOptions = useMemo(() => [{value: 'salesforce', label: 'Salesforce'}], []);
    const { required } = validators;

    const handleSuccess = useCallback(() => {
        setCheckError('');
        setCheckSuccess(translate.success);
    }, [translate, setCheckSuccess, setCheckError]);

    const onError = useCallback((error: any) => {
        const { response } = error;
        setCheckSuccess('');
        setCheckError(response?.data?.message || error?.message || translate.error);
    }, [translate, setCheckSuccess, setCheckError]);

    const { mutate: check, isLoading: checkIsLoading } = useTestPostThirdPartyConfigurationByPayload({ onSuccess: handleSuccess, onError});

    function handleCheck(values: any) {
        setCheckError('');
        setCheckSuccess('');
        check(values)
    }

    useEffect(() => {
        if (error) {
            setCheckError(error?.message || translate.error);
        }
    }, [error]);

    return (
        <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, submitting, form, pristine, invalid, values }) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <StyledForm onSubmit={handleSubmit} layout="vertical">
                    <Field
                        label={translate.type}
                        name={`type`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={LabelSelect}
                        options={typeOptions}
                        validate={required}
                        placeholder={translate.typePlaceholder}
                        disabled={isEditing}
                    />
                    <Condition when="type" is="salesforce">
                        <Alert showIcon type={"info"} message={(<>
                            {translate.crmMessage.split("\n").map(e => {return(<>{e}<br/></>)})}
                        </>)} />
                    </Condition>
                    {isAdmin ? (
                        <Field
                            label={translate.isCompanyLevel}
                            name={`isCompanyConfiguration`}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={LabelTogglebutton}
                            disabled={isEditing}
                        />
                    ) : null}
                    <Field
                        label={translate.enabled}
                        name={`enabled`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={LabelTogglebutton}
                    />
                    <Condition when="type" is="salesforce">
                        <SalesforceForm isEditing={isEditing} />
                    </Condition>
                    { checkSuccess !== '' ? (<Alert showIcon type={"success"} message={translate.checkResult} description={checkSuccess} />) : null }
                    { checkError !== '' ? (<Alert showIcon type={"error"} message={translate.checkResult} description={checkError} />) : null }
                    <Divider />
                    <Row justify={"end"} >
                        <Space>
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => handleCheck(values)}
                            color="primary"
                            loading={checkIsLoading}
                            disabled={isLoading || submitting || pristine || invalid}
                            size="large"
                        >
                            {translate.checkButton}
                        </Button>
                        <Button
                            icon={<CheckOutlined />}
                            onClick={form.submit}
                            type="primary"
                            loading={isLoading}
                            disabled={checkIsLoading || isSubmitDisabled || submitting || pristine || invalid}
                            size="large"
                        >
                            {translate.submit}
                        </Button>
                        </Space>
                    </Row>
                </StyledForm>
            )}
        </FinalForm>
    );
};

export default injectValidators(injectIntl(ThirdPartyConfigurationForm));