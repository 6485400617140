import React, { ReactElement } from 'react';
import { WrappedComponentProps } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ThirdPartyConfiguration from 'components/Settings/ThirdPartyConfiguration/index';
import { isUserSuperAdminSelector } from 'selectors/session';

type ContainerProps = {
    isAdmin: boolean;
} & WrappedComponentProps;

const Container = ({ isAdmin}: ContainerProps): ReactElement  => {
    return (<ThirdPartyConfiguration isAdmin={isAdmin} />);
}

const mapStateToProps = (state: any) => ({
    isAdmin: isUserSuperAdminSelector(state),
});

export default compose(
    connect(mapStateToProps, null),
)(Container as React.FunctionComponent<any>);
