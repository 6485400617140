import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { darken } from 'polished'; 
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Icon from 'components/Shared/Common/Icon';
import ContentTemplate from 'components/Shared/Feed/ItemTemplate/ContentTemplate';

const { Paragraph } = Typography;

const StyledBot = styled(Row)`
    width: 100%;
    background-color: ${props => props.theme.lighten};
    padding: 5px 10px;
    border-radius: 6px;
    position: relative;
    >.icon{
        display: flex;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin: 0 10px 0 6px;
        border-radius: 50%;
        background-color: ${props => darken(.1, props.theme.lighten)};
        svg{
            width: 18px;
            height: 18px;
        }
    }
    .ant{
        &-typography{
            margin-bottom: 0;
            line-height: 1.15;
        }
    }
    &:before{
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        border-radius: 6px 0 0 6px;
        background-color: ${props => props.theme.black};
        top: 0;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin-top: 10px;
    color: ${props => props.theme.mainColor2};
`;

interface GPTData {
    isFinished?: boolean;
    tokenUsed?: string;
}

type FeedItemBotProps = {
    name: string;
    message: string;
    status?: number;
    additionalData?: GPTData;
    onLoad?: () => void;
} & WrappedComponentProps;

const FeedItemBot = ({
    name = '',
    message = '',
    status,
    intl,
    onLoad = () => {},
    additionalData: { isFinished } = {},
}: FeedItemBotProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded && onLoad) {
            setIsLoaded(true);
            onLoad?.();
        }
    }, [onLoad]);

    return (
        <StyledBot justify="start" wrap={false}>
            <Icon iconName="Gpt" />
            <Col>
                <Paragraph strong>{name}</Paragraph>
                {status === -1 && <Paragraph strong>{intl.formatMessage({ id: 'error.default' })}</Paragraph>}
                {status === 0 && <Paragraph strong><LoadingOutlined /></Paragraph>}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {(status === 1 || status === undefined) && <ContentTemplate content={message} />}
                {!isFinished && status === 1 && (
                    <StyledParagraph strong>
                        <WarningOutlined /> {intl.formatMessage({ id: 'bot.missingAccess' })}
                    </StyledParagraph>
                )}
            </Col>
        </StyledBot>
    );
};

export default injectIntl(FeedItemBot as React.FunctionComponent<FeedItemBotProps>);
