const validators = {
    username: value => value.length > 3,
    password: value => value.length > 3,
    server: value => value.length > 7,
    port: value => value.length > 2,
    domain: value => value.length > 3,
    proxy: value => value.length > 3,
    lastname: value => value.length > 0,
    firstname: value => value.length > 0,
    title: value => value.length > 0,
    name: value => value.length > 0,
    description: value => value.length > 4,
    company: value => value.length > 0,
    education: value => value.length > 0,
    search: value => value.length > 2,
    gender: value => [0, 1].indexOf(value) !== -1,
    biography: () => true,
    avatar: () => true,
    skillLevel: () => true,
    email: value => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) // eslint-disable-line no-useless-escape
};

/*
*
*   => formValidator: Basic input validator
*   => return boolean
*
*   => Example:
*       import { formValidator } from 'utils/formValidator;
*
*       const emailValidator = input => formValidator('email')(input)
*
*
* */

export const formValidator = (key) => {

    if (validators[key] && typeof validators[key] === 'function') {

        return validators[key];

    }
    return () => true;

};

export const composeValidators = (...v) => value =>
    v.reduce((error, validator) => error || validator(value), undefined)

/*
*
*   => default export : redux-form based form validator
*   => return errorMessage: string || undefined
*
*   => Example:
*       import Validator from 'utils/formValidator;
*
*       const { email } = Validator(intl);
*
*       <ReduxFormConnectedComponent
*           {...otherProps}
*           validate={[email])
*       />
*
*
* */

export default (intl) => {

    const required = value => (value ? undefined : intl.formatMessage({
        id: 'form.input.error.required',
        defaultMessage: 'Required'
    }));

    const minLength = min => value => (value && value.length < min ? intl.formatMessage({
        id: 'form.input.error.minChar',
        defaultMessage: 'Must be {min} characters or more'
    }, {
        min
    }) : undefined);

    const maxLength = max => value => (value && value.length > max ? intl.formatMessage({
        id: 'form.input.error.maxChar',
        defaultMessage: 'Must be {max} characters or less'
    }, {
        max
    }) : undefined);

    const ip = value => (value && !/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/i.test(value) ? intl.formatMessage({
        id: 'form.input.error.notValidIp',
        defaultMessage: 'Not a valid IP'
    }) : undefined);

    const domain = value => (value && !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/i.test(value) ? intl.formatMessage({
        id: 'form.input.error.notValidDomain',
        defaultMessage: 'Not a valid Domain'
    }) : undefined);

    const number = value => (value && isNaN(Number(value)) ? intl.formatMessage({
        id: 'form.input.error.mustBeNumber',
        defaultMessage: 'Must be a number'
    }) : undefined);

    const minValue = min => value => (value && value < min ? intl.formatMessage({ // eslint-disable-line
        id: 'form.input.error.minVal',
        defaultMessage: 'Must be at least {min}'
    }, {
        min
    }) : undefined);

    const maxValue = max => value => (value && value > max ? intl.formatMessage({
        id: 'form.input.error.maxVal',
        defaultMessage: 'Must be at max {max}'
    }, {
        max
    }) : undefined);

    const port = value => (maxValue(65535)(value) ? intl.formatMessage({
        id: 'form.input.error.port',
        defaultMessage: 'Port is to high'
    }) : undefined);

    const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? intl.formatMessage({
        id: 'form.input.error.email',
        defaultMessage: 'Invalid email address'
    }) : undefined);

    const emails = value => value?.map(email).find(x => !!x);

    const phone = value => (!/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(value) ? intl.formatMessage({
        id: 'form.input.error.password',
        defaultMessage: 'Password should container at least 6 chars'
    }) : undefined);

    const oldPassword = minLength(3);
    const password = minLength(6);

    const sipUsernameLength = minLength(3);
    const sipPasswordLength = minLength(6);

    const roomFirstnameLength = (value) => minLength(1)(value) || maxLength(100)(value);
    const roomLastnameLength = (value) => minLength(1)(value) || maxLength(100)(value);
    const roomNameLength = maxLength(30);
    const roomInvitTopicLength = (value) => minLength(3)(value) || maxLength(150)(value);
    const roomInvitDescriptionLength = (value) => minLength(5)(value) || maxLength(1000)(value);

    const userBioLength = (value) => minLength(3)(value) || maxLength(250)(value);
    const userCompanyLength = (value) => minLength(3)(value) || maxLength(100)(value);
    const userEducationLength = (value) => minLength(3)(value) || maxLength(100)(value);
    const userFirstNameLength = (value) => minLength(1)(value) || maxLength(100)(value);
    const userLastNameLength = (value) => minLength(1)(value) || maxLength(100)(value);
    const userTitleLength = (value) => minLength(2)(value) || maxLength(100)(value);

    const feedNameLength = (value) => minLength(3)(value) || maxLength(100)(value);
    const feedDescriptionLength = (value) => minLength(3)(value) || maxLength(250)(value);

    const activityLength = (value) => minLength(3)(value) || maxLength(12000)(value);
    const searchLength = (value) => minLength(3)(value) || maxLength(100)(value);
    const feedItemLength = maxLength(12000);

    return ({
        roomInvitTopicLength,
        roomInvitDescriptionLength,
        feedItemLength,
        activityLength,
        searchLength,
        required,
        ip,
        number,
        port,
        email,
        emails,
        phone,
        oldPassword,
        password,
        userBioLength,
        userCompanyLength,
        userEducationLength,
        feedNameLength,
        feedDescriptionLength,
        sipUsernameLength,
        sipPasswordLength,
        roomFirstnameLength,
        roomLastnameLength,
        userFirstNameLength,
        userLastNameLength,
        userTitleLength,
        roomNameLength,
        domain
    });
};
