import { injectIntl, WrappedComponentProps } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Typography } from 'antd';
import styled from 'styled-components';
import { i18n } from '@amplement/backend-connector';

import Icon from 'components/Shared/Common/Icon';

const { Text, Paragraph } = Typography;

const DataRow = styled(Row)`
  margin-bottom: 5px;
`;

type FeedItemCRMNoteProps = {
    id: string;
    body?: string;
    createdAt: string;
    updatedAt: string;
    url: string
    onLoad?: () => void;
} & WrappedComponentProps;

const FeedItemCRMNote = ({
    intl,
    body,
    createdAt,
    updatedAt,
    url,
    onLoad = () => {}
}: FeedItemCRMNoteProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const translate = useMemo(() => ({
        content: intl.formatMessage({ id: 'feedItem.metas.crm.note.content' }),
        createdAt: intl.formatMessage({ id: 'global.date.createdAt' }),
        updatedAt: intl.formatMessage({ id: 'global.date.updatedAt' }),
        details: intl.formatMessage({ id: 'feedItem.metas.crm.details' }),
        creationDate: `${i18n.formatDate(new Date(createdAt))} ${i18n.formatTime(new Date(createdAt))}`,
        lastUpdatedAt: `${i18n.formatDate(new Date(updatedAt))} ${i18n.formatTime(new Date(updatedAt))}`
    }), [intl, createdAt, updatedAt]);

    useEffect(() => {
        if (!isLoaded && onLoad) {
            setIsLoaded(true);
            onLoad?.();
        }
    }, [onLoad]);

    return (
        <>
            {body ? (<DataRow><Paragraph><Text strong>{translate.content}</Text> {body}</Paragraph></DataRow>) : null}
            <DataRow><Paragraph><Text strong>{translate.createdAt}</Text> {translate.creationDate}</Paragraph></DataRow>
            <DataRow><Paragraph><Text strong>{translate.updatedAt}</Text> {translate.lastUpdatedAt}
            </Paragraph></DataRow>
            <Row><Button href={url} target="_blank" size={'small'}
                         icon={<Icon iconName={'Link'} />}>{translate.details}</Button></Row>
        </>
    );
};

export default injectIntl(FeedItemCRMNote as React.FunctionComponent<FeedItemCRMNoteProps>);