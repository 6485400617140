import React, { memo, useEffect, useMemo, useState } from 'react';
import styled from "styled-components";
import { Row, Grid } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { connect } from 'react-redux';

import { selectors, apis } from '@amplement/backend-connector';
import { getCompanyTabByNameSelector } from 'selectors/company';

import CallbuilderCard from 'screens/Dashboard/CallbuilderCard';
import VoiceMail from 'screens/Dashboard/VoiceMailCard';
import MissedCall from 'screens/Dashboard/MissedCallCard';
import MyLine from 'screens/Dashboard/MyLine';
import ForwardCard from 'screens/Dashboard/ForwardCard';
import DevicesCard from 'screens/Dashboard/DevicesCard';

import { StyledPageLayout } from 'components/Shared/style';

import { StyledCol } from 'screens/Dashboard/styles';

const { useBreakpoint } = Grid;

const StyledRow = styled(Row)`
    width: 100%;
    row-gap: 10px;
`;

const StyledSideRow = styled(Row)`
    row-gap: 10px;
`;

const gutter: Gutter | [Gutter, Gutter] = [20, 20];

type NotificationStructureProps = {
    voiceMailFirst: boolean;
    isVoiceMailSupported: boolean;
    isEnabled: boolean;
};

const NotificationStructure = ({
    voiceMailFirst,
    isVoiceMailSupported,
    isEnabled
}: NotificationStructureProps): JSX.Element => voiceMailFirst ? (
    <>
        {isVoiceMailSupported && isEnabled && (
            <StyledCol span={24}>
                <VoiceMail />
            </StyledCol>
        )}
        <MissedCall />
    </>
) : (
    <>
        <MissedCall />
        {isVoiceMailSupported && isEnabled && (
            <StyledCol span={24}>
                <VoiceMail />
            </StyledCol>
        )}
    </>
);

const StyledContainerPageLayout = styled(StyledPageLayout)`
    background-color: #fafafa !important;
`;

interface DashboardProps {
    unreadVoiceMailCount: number;
    isEnabled: boolean;
    isVoiceMailSupported: boolean;
    _account: string;
}

export const DashboardPage: React.FC<DashboardProps> = ({
    unreadVoiceMailCount = 0,
    isEnabled = false,
    isVoiceMailSupported = false,
    _account
}: DashboardProps): JSX.Element => {
    const [voiceMailFirst, showVoiceMailFirst] = useState(false);

    useEffect(() => {
        if (unreadVoiceMailCount > 0) {
            showVoiceMailFirst(true);
        }
    }, [unreadVoiceMailCount]);

    const { lg, xl, xxl } = useBreakpoint();

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    return (
        <StyledContainerPageLayout className="ui-dashboard-page">
            <StyledRow gutter={gutter}>
                {!isLargeScreen && (
                    <>
                        {!!(isEnabled && _account) && (
                            <StyledCol xs={24}>
                                <MyLine />
                            </StyledCol>
                        )}
                        <NotificationStructure
                            isVoiceMailSupported={isVoiceMailSupported}
                            voiceMailFirst={voiceMailFirst}
                            isEnabled={isEnabled}
                        />
                    </>
                )}
                <StyledCol xs={24} xl={14}>
                    <CallbuilderCard />
                </StyledCol>
                <StyledCol xs={24} xl={10}>
                    <StyledSideRow gutter={gutter}>
                        {isLargeScreen && (
                            <>
                                {!!(isEnabled && _account) && (
                                    <StyledCol span={24}>
                                        <MyLine />
                                    </StyledCol>
                                )}
                                <NotificationStructure
                                    isVoiceMailSupported={isVoiceMailSupported}
                                    voiceMailFirst={voiceMailFirst}
                                    isEnabled={isEnabled}
                                />
                            </>
                        )}
                        {_account && isEnabled && !!apis?.callForward?.$getAllForwardsSettings && (
                            <StyledCol span={24}>
                                <ForwardCard />
                            </StyledCol>
                        )}
                        <StyledCol span={24}>
                            <DevicesCard />
                        </StyledCol>
                    </StyledSideRow>
                </StyledCol>
            </StyledRow>
        </StyledContainerPageLayout>
    );
};

const mapStateToProps = (state) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { options: { isEnabled = true, isEditable = true } = {}} = getCompanyTabByNameSelector(state, 'phone') || {};
    const { status, id: _account } = selectors.sip.getSIPAccount(state) || {};

    return {
        _account,
        status,
        unreadVoiceMailCount: selectors.voiceMails.unreadVoiceMailCountSelector(state),
        isVoiceMailSupported: selectors.voiceMails.voiceMailSupportedSelector(state),
        isEnabled: !!isEnabled,
        isEditable: !!isEditable
    }
};

export default connect(mapStateToProps)(memo(DashboardPage));
