import React , { ReactElement, memo } from 'react';

import Salesforce from 'common/icons/salesforce';

type ThirdPartyIconProps = {
    name: string;
    style?: object;
}

const ThirdPartyIcon = ({name, style = {}}: ThirdPartyIconProps) => {
    let icon: ReactElement|null = null;
    switch (name) {
        case 'salesforce': {
            icon = <Salesforce className={'icon'} style={style} />;
            break;
        }
        default:
            break;
    }
    return (icon);
}

export default memo(ThirdPartyIcon);