import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ThirdPartyConfigurationForm from 'components/Settings/ThirdPartyConfiguration/ThirdPartyConfigurationForm/index';
import {
    usePostThirdPartyConfiguration,
    usePatchThirdPartyConfiguration,
    useThirdPartyConfigurationById
} from 'hooks/useThirdPartyConfiguration';
import { isUserSuperAdminSelector } from 'selectors/session';
import injectValidators from 'components/Shared/Forms/injectValidators';

type ContainerFormProps = {
    isAdmin: boolean;
    id: string;
} & WrappedComponentProps;

const Container = ({intl, isAdmin, id}: ContainerFormProps): ReactElement  => {
    const history = useHistory()
    const translate = useMemo(() => ({
        saveError: intl.formatMessage({id: 'thirdPartyConfigurations.save.error'}),
        saveSuccess: intl.formatMessage({id: 'thirdPartyConfigurations.save.success'}),
    }), [intl]);
    const [initialValues, setInitialValues] = useState({});
    const [isFormLocked, setIsFormLocked] = useState(false);

    const fetchSuccess = useCallback((response: any) => {
        const values = {...response.data};
        delete values.id;
        delete values.createdAt;
        delete values.updatedAt;
        delete values._company;
        delete values.category;
        setInitialValues(values);
    }, [translate, setIsFormLocked]);

    const handleSuccess = useCallback(() => {
        setIsFormLocked(true);
        message.success({
            key: 'saveThirdPartyConfigurationSuccess',
            content: translate.saveSuccess,
            duration: 3,
        }, 3, () => history.push('/settings?tabs=third-party'));
    }, [translate, setIsFormLocked]);

    const onError = useCallback((error: any) => {
        const { response } = error;
        message.error({
            key: 'saveThirdPartyConfigurationError',
            content: response?.data?.message || error?.message || translate.saveError,
            duration: 3,
        });
    }, [translate]);

    const { mutate: getOne, isLoading } = useThirdPartyConfigurationById({ onSuccess: fetchSuccess, onError});
    const { mutate: post, isLoading: postIsLoading } = usePostThirdPartyConfiguration({ onSuccess: handleSuccess, onError});
    const { mutate: patch, isLoading: patchIsLoading } = usePatchThirdPartyConfiguration({ onSuccess: handleSuccess, onError});

    const handleSubmit = useCallback((values: any) => {
        if (id) {
            const payload = {...values};
            delete payload.isCompanyConfiguration;
            delete payload.type;
            delete values.settings.credentials.type;
            patch({id, payload})
        } else {
            post(values);
        }
    }, [post]);

    useEffect(() => {
        if (id) {
            getOne(id);
        }
    }, [id]);

    const loadingState = isLoading||postIsLoading||patchIsLoading;
    return (<ThirdPartyConfigurationForm isAdmin={isAdmin} initialValues={initialValues} onSubmit={handleSubmit} isLoading={loadingState} isSubmitDisabled={loadingState||isFormLocked} isEditing={!!id} />);
}

const mapStateToProps = (state: any) => ({
    isAdmin: isUserSuperAdminSelector(state),
});

export default compose(
    connect(mapStateToProps, null),
    injectValidators,
    injectIntl
)(Container as React.FunctionComponent<any>);
