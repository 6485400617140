import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Alert } from 'antd';

import Link from 'components/Shared/Common/Link';
import  { i18n } from '@amplement/backend-connector';

import { getUrl } from 'utils/url';

const { Title, Paragraph, Text } = Typography;

const formatMessages = intl => ({ createdAt, hrefFaq, hrefProfile, username }) => ({
    feedPlaceholderMulticast: createdAt ? intl.formatMessage({
        id: 'feed.multicast.create.label',
    }, {
        date: `${i18n.formatDate(new Date(createdAt))}`,
        time: `${i18n.formatTime(new Date(createdAt))}`
    }) : '',
    feedPlaceholderUnicast: createdAt ? intl.formatMessage({
        id: 'feed.unicast.create.label',
    }, {
        date: `${i18n.formatDate(new Date(createdAt))}`,
        time: `${i18n.formatTime(new Date(createdAt))}`
    }) : '',
    multicastByUser: intl.formatMessage({
        id: 'feed.placeholder.firstmessage.multicastLabel',
    }, {
        username: <Link href={hrefProfile} className="name">{username}</Link>
    }),
    unicastByUser: intl.formatMessage({
        id: 'feed.placeholder.firstmessage.unicastLabel',
    }, {
        username: <Link href={hrefProfile} className="name">{username}</Link>
    }),
    feedPlaceholderSubtitle: intl.formatMessage({
        id: 'feed.placeholder.firstmessage.subtitle',
        defaultMessage: 'C\'est ici que tout a commencé'
    }, {
        link: <Link href={hrefFaq} target="_parent">
            {intl.formatMessage({
                id:'feed.placeholder.firstmessage.hrefLabelFaq',
                defaultMessage: 'Consulter notre FAQ'
            })}
        </Link>
    }),
});

const FeedItemStartupPlaceholder = (props) => {
    const {
        intl,
        feedName,
        userName,
        _user,
        _feed,
        createdAt,
        language,
    } = props;
    const hrefProfile = getUrl('profile', { _user });
    const i18nMsg = formatMessages(intl)({ createdAt, createdBy: '', hrefFaq: `/${language}/faq`, hrefProfile, username: userName });
    const subTitle = !feedName ? i18nMsg.feedPlaceholderUnicast : i18nMsg.feedPlaceholderMulticast;
    return (
        <>
            <Title className="s-title" level={4} copyable={{
                text: window.location.origin + getUrl('feed', { _feed }),
                tooltips: intl.formatMessage({
                    id: 'global.copyClipboard',
                    defaultMessage: 'Copier'
                })
            }} >
                <>
                    {feedName  && (
                        <>
                            {i18nMsg.multicastByUser}
                        </>
                    )}
                    {!feedName && _user && (
                        <>
                            {i18nMsg.unicastByUser}
                        </>
                    )}
                </>
            </Title>
            <Paragraph>
                <Text type="secondary">{subTitle}</Text><br/>
                <Alert description={i18nMsg.feedPlaceholderSubtitle} 
                    type="info" 
                    showIcon 
                />
            </Paragraph>
        </>
    )
};

FeedItemStartupPlaceholder.propTypes = {
    intl: PropTypes.object.isRequired,
    _feed: PropTypes.string.isRequired,
    _user: PropTypes.string,
    createdAt: PropTypes.string,
    feedName: PropTypes.string,
    userName: PropTypes.string,
    language: PropTypes.string
};

export default injectIntl(memo(FeedItemStartupPlaceholder));
