import React, { useMemo } from 'react';
import { Typography, Grid, Result, Tooltip } from 'antd';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';

import Icon from 'components/Shared/Common/Icon';
import StyledCallButton from 'components/CallBuilder/DefaultResults/style';

import { colors } from 'common/themes/Colors';

import { StyledCard, StyledEmptyCardWrapper } from 'screens/Dashboard/styles';

import useSipAccount from 'hooks/useSipAccount';
import useSipAccounts from 'hooks/useSipAccounts';
import Link from 'components/Shared/Common/Link';
import Button from 'components/Shared/Common/Button';

import { getUrl } from 'utils/url';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h4 {
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;

        @media (min-width: 1480px) and (max-width: 1600px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
        }

        @media (min-width: 1360px) and (max-width: 1480px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
        }

        @media (min-width: 1200px) and (max-width: 1360px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
        }
    }
`;

export const MyLine = ({ intl }) => {
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account, isLoading, error, isError } = useSipAccount({ _sipAccount });
    const { phone: { shortNumber = '', externalNumber = '', externalInternationalNumber = '' } = {} } = account || {};

    const { lg, xl, xxl } = useBreakpoint();

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const formatedTitle = useMemo(() => {
        const displayableNumber = externalInternationalNumber || externalNumber || shortNumber;
        return (
            <>
                {isLoading && <LoadingOutlined style={{ marginLeft: 10 }} />}
                {displayableNumber || ''}
            </>
        )
    }, [isLoading, intl, externalNumber, shortNumber, externalInternationalNumber]);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="Info" />
            <StyledTitle>
                <div className="label">
                    <Tooltip title={formatedTitle}>
                        <Title level={4}>
                            {formatedTitle}
                        </Title>
                    </Tooltip>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link
                    href={getUrl('selfcare')}
                    className="text"
                >
                    <StyledCallButton>
                        <Button iconName="Settings">
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {isLargeScreen && <p>{intl.formatMessage({ id: 'dashboard.parameters.configuration' })}</p>}
                        </Button>
                    </StyledCallButton>
                </Link>
            </StyledTitle>
        </StyledContainer>
    ), [formatedTitle, intl, isLargeScreen]);

    if (error || isError) {
        return (
            <StyledCard title={cardTitle}>
                <Result
                    status="error"
                    subTitle={intl.formatMessage({ id: 'call.error.cannotGetCallInformations' })}
                />
            </StyledCard>
        );
    }

    return <StyledEmptyCardWrapper title={cardTitle} />;
};

export default injectIntl(MyLine);
