import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Typography } from 'antd';
import { darken, lighten } from 'polished';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { i18n } from '@amplement/backend-connector';

import Icon from 'components/Shared/Common/Icon';
import FeedItemCRMNotes, { NoteData } from 'components/Shared/Feed/FeedItemCRMNotes';
import ThirdPartyIcon from 'components/Shared/Common/ThirdPartyIcon';

const { Text, Title, Paragraph } = Typography;

const LeftCol = styled(Col)`
  > .icon {
    display: flex;
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    margin: 0 10px 0 6px;
    border-radius: 50%;
    background-color: ${props => darken(.1, props.theme.lighten)};

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

const RightCol = styled(Col)`
  width: 100%;
  align-content: center;
`;

const StyledCRM = styled(Row)`
  width: 100%;
  background-color: ${props => props.theme.lighten};
  padding: 10px 10px;
  border-radius: 6px;
  position: relative;

  .ant {
    &-typography {
      margin-bottom: 0;
      line-height: 1.15;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
    border-radius: 6px 0 0 6px;
    background-color: ${props => lighten(0.4, props.theme.defaultGroupColor)};
    top: 0;
  }
`;

const DataRow = styled(Row)`
  margin-bottom: 5px;
  width: 100%;
`;


interface CRMData {
    id: string;
    isDeleted?: boolean;
    firstname?: string;
    lastname?: string;
    salutation?: string;
    address?: string;
    phones?: {
        phone?: string;
        mobile?: string;
        home?: string;
        other?: string;
        assistant?: string;
    };
    email?: string;
    title?: string;
    createdAt: string;
    lastUpdateAt: string;
    notes?: NoteData[],
    url: string;
}

type FeedItemCRMProps = {
    source: string;
    error?: string;
    count?: string;
    status?: number;
    data?: CRMData;
    onLoad?: () => void;
} & WrappedComponentProps;

const FeedItemCRM = ({
    intl,
    source,
    error,
    count,
    status,
    data = { createdAt: '', lastUpdateAt: '', url: '', id: '' },
    onLoad = () => {}
}: FeedItemCRMProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const { title, email, url, phones, createdAt, lastUpdateAt, id } = data;
    const {
        phone,
        mobile,
        home,
        other,
        assistant
    } = phones || {};
    const hasPhone = phone || mobile || home || other || assistant;

    const icon = useMemo(() => source ? (<ThirdPartyIcon name={source} />) : null, [source]);

    const translate = useMemo(() => ({
        phone: intl.formatMessage({ id: 'feedItem.metas.crm.phones.phone' }),
        mobile: intl.formatMessage({ id: 'feedItem.metas.crm.phones.mobile' }),
        home: intl.formatMessage({ id: 'feedItem.metas.crm.phones.home' }),
        other: intl.formatMessage({ id: 'feedItem.metas.crm.phones.other' }),
        assistant: intl.formatMessage({ id: 'feedItem.metas.crm.phones.assistant' }),
        createdAt: intl.formatMessage({ id: 'global.date.createdAt' }),
        updatedAt: intl.formatMessage({ id: 'global.date.updatedAt' }),
        details: intl.formatMessage({ id: 'feedItem.metas.crm.details' }),
        email: intl.formatMessage({ id: 'settings.menu.email' }),
        title: intl.formatMessage({ id: 'profile.fields.title.placeholder' }),
        creationDate: `${i18n.formatDate(new Date(createdAt))} ${i18n.formatTime(new Date(createdAt))}`,
        lastUpdatedAt: `${i18n.formatDate(new Date(lastUpdateAt))} ${i18n.formatTime(new Date(lastUpdateAt))}`,
        noResults: intl.formatMessage({ id: `feedItem.metas.crm.errors.noResults` }),
        errorMessage: intl.formatMessage({ id: `feedItem.metas.crm.errors.${error}` })
    }), [intl, createdAt, lastUpdateAt, error]);

    useEffect(() => {
        if (!isLoaded && onLoad) {
            setIsLoaded(true);
            onLoad?.();
        }
    }, [onLoad]);

    if (status === 0) {
        return (<StyledCRM justify="start" wrap={false}>
            <Col>
                <Paragraph strong><LoadingOutlined /></Paragraph>
            </Col>
        </StyledCRM>);
    }

    if (error && status === 2) {
        return (<StyledCRM justify="start" wrap={false}>
            <LeftCol>
                {icon}
            </LeftCol>
            <RightCol>
                <Row>
                    <Paragraph strong>
                        <Text type={'danger'}>{translate.errorMessage}</Text>
                    </Paragraph>
                </Row>
            </RightCol>
        </StyledCRM>);
    }

    if (!error && id === '') {
        return (<StyledCRM justify="start" wrap={false}>
            <LeftCol>
                {icon}
            </LeftCol>
            <RightCol>
                <Row>
                    <Paragraph strong>{translate.noResults}</Paragraph>
                </Row>
            </RightCol>
        </StyledCRM>);
    }

    return (
        <StyledCRM justify="start" wrap={false}>
            <LeftCol>
                {icon}
                {count ? (<Row justify={'center'}><Text type="secondary">{count}</Text></Row>) : null}
            </LeftCol>
            <RightCol>
                <DataRow><Title level={4}>{data?.salutation} {data?.firstname} {data?.lastname}</Title></DataRow>
                {title ? (
                    <DataRow><Paragraph><Text strong>{translate.title}</Text> {title}</Paragraph></DataRow>) : null}
                {email ? (
                    <DataRow><Paragraph><Text strong>{translate.email}</Text> {email}</Paragraph></DataRow>) : null}
                {hasPhone ? (<DataRow>
                    <ul>
                        {phone ? (<li><Text strong>{translate.phone}</Text> {phone}</li>) : null}
                        {mobile ? (<li><Text strong>{translate.mobile}</Text> {mobile}</li>) : null}
                        {home ? (<li><Text strong>{translate.home}</Text> {home}</li>) : null}
                        {other ? (<li><Text strong>{translate.other}</Text> {other}</li>) : null}
                        {assistant ? (<li><Text strong>{translate.assistant}</Text> {assistant}</li>) : null}
                    </ul>
                </DataRow>) : null}
                <DataRow>
                    <Paragraph><Text strong>{translate.createdAt}</Text> {translate.creationDate}</Paragraph>
                </DataRow>
                <DataRow>
                    <Paragraph><Text strong>{translate.updatedAt}</Text> {translate.lastUpdatedAt}</Paragraph>
                </DataRow>
                <FeedItemCRMNotes notes={data?.notes} />
                <Row>
                    <Button href={url} target="_blank" size={'small'} icon={<Icon iconName={'Link'} />}>
                        {translate.details}
                    </Button>
                </Row>
            </RightCol>
        </StyledCRM>
    );
};

export default injectIntl(FeedItemCRM as React.FunctionComponent<FeedItemCRMProps>);
