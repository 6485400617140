import { connect } from 'react-redux';
import  { selectors, utils } from '@amplement/backend-connector';

import { getUserSettingsSelector, getUserByIdSelector } from 'selectors/user';
import FeedItemStartupPlaceholder from './index';

const mapStateToProps = (state, { _feed }) => {
    const feed = selectors.feeds.feedByIdSelector(state, _feed);
    const language = getUserSettingsSelector(state)?.language;
    const { createdAt, _user, name } = feed || {};
    let userName;
    if(_user) {
        const user = getUserByIdSelector(state, _user);
        userName = utils.strings.getFullname(user);
    }
    return ({
        _feed,
        createdAt,
        userName,
        feedName: name,
        _user,
        language,
        isEditable: feed?.isOwned
    });
};

export default connect(mapStateToProps, null)(FeedItemStartupPlaceholder);
