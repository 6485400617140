import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectors } from '@amplement/backend-connector';
import {
    NotificationOutlined,
    MailOutlined,
    ToolOutlined,
    LockOutlined,
    SettingOutlined,
    AppstoreAddOutlined
} from '@ant-design/icons';

import { getCompanyTabByNameSelector } from 'selectors/company';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import GeneralSettingsForm from 'components/Settings/GeneralSettingsForm/container';
import ResetEmailForm from 'components/Settings/ResetEmailForm';
import ResetPasswordForm from 'components/Settings/ResetPasswordForm/container';
import NotificationForm from 'components/Settings/NotificationForm/container';
import SipSettings from 'components/Shared/Drawers/Sip/Settings/container';
import Placeholder from 'components/Shared/Drawers/Sip/Placeholder';
import Button from 'components/Shared/Common/Button';
import desktopNotificationManager from 'services/desktopNotificationManager';
import { Styled } from 'screens/Settings/styles';
import { StyledTabs } from 'components/Shared/styles';
import UiIcon from 'components/Shared/Common/Icon';
import ThirdPartyConfiguration from 'components/Settings/ThirdPartyConfiguration/container';
import { getQueryStringParameter } from 'utils/url';

let tabs = [
    {
        title: {
            id: 'settings.menu.general',
            defaultMessage: 'General'
        },
        name: 'general',
        Icon: <ToolOutlined />,
        Component: GeneralSettingsForm
    },
    {
        title: {
            id: 'settings.menu.email',
            defaultMessage: 'E-mail'
        },
        name: 'email',
        Icon: <MailOutlined />,
        Component: ResetEmailForm
    },
    {
        title: {
            id: 'settings.menu.password',
            defaultMessage: 'Mot de passe'
        },
        name: 'password',
        Icon: <LockOutlined />,
        Component: ResetPasswordForm
    },
    {
        title: {
            id: 'settings.menu.notifications',
            defaultMessage: 'Notifications'
        },
        name: 'notification',
        Icon: <NotificationOutlined />,
        visible: desktopNotificationManager.isSupported(),
        Component: NotificationForm
    },
    {
        title: {
            id: 'call.tabs.sip.settings',
            defaultMessage: 'Configuration'
        },
        name: 'settings',
        Icon: <SettingOutlined />,
        Component: () => null
    },
    {
        title: {
            id: 'settings.menu.third-party',
            defaultMessage: 'Third Party'
        },
        name: 'third-party',
        Icon: <AppstoreAddOutlined />,
        Component: ThirdPartyConfiguration
    }
];

const Settings = ({
    intl,
    onDeleteAccount,
    isPasswordEditable = false,
    isEmailEditable = true,
    isSignUpAllowed = true,
    isEnabled,
    isEditable,
    _account,
}) => {
    const [isAddAccount, setIsAddAccount] = useState();
    const [activeTab, setActiveTab] = useState(getQueryStringParameter('tabs')(location.search) || 'general');
    const handleTabChange = useCallback((tab) => setActiveTab(tab), [setActiveTab]);

    const handleAddAccount = useCallback(() => setIsAddAccount(true), [setIsAddAccount]);
    const items = useMemo(() => {
        if (!isPasswordEditable) {
            tabs = tabs.filter(t => t.name !== "password");
        }
        if (!isEmailEditable) {
            tabs = tabs.filter(t => t.name !== "email");
        }

        const settingsTab = tabs.find(t => t.name === 'settings');
        if (settingsTab) {
            if (!_account && !isAddAccount) {
                settingsTab.Component = () => <Placeholder addAccount={handleAddAccount} />;
            } else {
                settingsTab.Component = SipSettings;
            }
            settingsTab.visible = !!(isEnabled && isEditable);
        }

        return tabs
            .filter(x => x.visible !== false)
            .map(({ Component, name, title, Icon }) => ({
                label: (
                    <span>
                        {Icon}
                        &nbsp;{intl.formatMessage(title)}
                    </span>
                ),
                key: name,
                children: name === activeTab && (
                    <Component />
                )
            }));
    }, [
        intl, tabs, isPasswordEditable,
        isEmailEditable, activeTab, _account,
        isAddAccount, handleAddAccount, isEnabled,
        isEditable
    ]);

    return (
        <Styled>
            <CategoryTitle
                label={intl.formatMessage({
                    id: 'settings.title',
                    defaultMessage: 'Paramètres'
                })}
                icon={<UiIcon size="xl" iconName="Settings" />}
            />
            <div className="tabs-wrapper">
                <StyledTabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    items={items}
                />
            </div>
            {isSignUpAllowed && (
                <div className="account-delete">
                    <Button color="danger" onClick={onDeleteAccount}>
                        {intl.formatMessage({
                            id: 'settings.menu.delete',
                            defaultMessage: 'Supprimer ce compte'
                        })}
                    </Button>
                </div>
            )}
        </Styled>
    );
}

Settings.propTypes = {
    intl: PropTypes.any.isRequired,
    onDeleteAccount: PropTypes.func.isRequired,
    isPasswordEditable: PropTypes.bool,
    isSignUpAllowed: PropTypes.bool,
    isEmailEditable: PropTypes.bool,
};

const mapStateToProps = state => {
    const { id: _account } = selectors.sip.getSIPAccount(state) || {};
    const { options: { isEnabled = true, isEditable = true } = {}} = getCompanyTabByNameSelector(state, 'phone') || {};

    return ({
        _account,
        isEnabled,
        isEditable,
    });
};

export default connect(mapStateToProps)(injectIntl(Settings));
