import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { constants } from '@amplement/backend-connector';

import { getUrl } from 'utils/url';
import { getCurrentUserIdSelector } from 'selectors/user';
import Icon from 'components/Shared/Common/Icon';
import NotificationLayout from 'components/Shared/Notification/Layout';
import { NOTIFICATION_ROOM_TYPES } from 'const/notification';
import classNames from 'classnames';
import AvatarStatus from 'components/Shared/User/Avatar';
import RecallButton from 'components/Room/RecallButton';
import { getStatusById } from 'selectors/presence';
import Link from '../Common/Link';
import AvatarList from '../Call/AvatarList';

export const getIconName = (isCaller, type, isVideoCall, members) => {
    const selectIcon = (audio, video) => isVideoCall ? video : audio;

    if (isCaller) {
        switch (type) {
            case NOTIFICATION_ROOM_TYPES.OUTGOING:
                return selectIcon('PhoneCall', 'Video');
            case NOTIFICATION_ROOM_TYPES.MISSEDBUTLIVE:
            case NOTIFICATION_ROOM_TYPES.MISSED:
                return selectIcon('PhoneMissed', 'VideoMissed');
            case NOTIFICATION_ROOM_TYPES.HANGUP:
            default: {
                const isAnswered = members.some(m => !m.isMyUser
                    && m.status !== constants.rooms.USER_IN_ROOM_STATUS.WAITING
                    && m.status !== constants.rooms.USER_IN_ROOM_STATUS.MISSED);
                if (isAnswered) {
                    return selectIcon('PhoneOutgoing', 'VideoOutgoing');
                }
                return selectIcon('PhoneMissed', 'VideoMissed');
            }
        }
    }

    switch (type) {
        case NOTIFICATION_ROOM_TYPES.MISSEDBUTLIVE:
        case NOTIFICATION_ROOM_TYPES.MISSED:
            return selectIcon('PhoneMissed', 'VideoMissed');
        case NOTIFICATION_ROOM_TYPES.INCOMING:
            return selectIcon('Phone', 'Video');
        case NOTIFICATION_ROOM_TYPES.TAKEOFF:
            return selectIcon('PhoneCall', 'Video');
        case NOTIFICATION_ROOM_TYPES.FORWARDEDMANUALLY:
            return selectIcon('PhoneForwarded', 'Video');
        case NOTIFICATION_ROOM_TYPES.FORWARDEDAUTOMATICALLY:
            return selectIcon('PhoneForwarded', 'Video');
        case NOTIFICATION_ROOM_TYPES.HANGUP:
        default:
            return selectIcon('PhoneIncoming', 'VideoIncoming');
    }
}

const UserLink = memo(({ phonenumber, fullname, _user, className }) => {
    if (phonenumber) {
        if (fullname && fullname !== phonenumber) {
            return `${fullname} n°${phonenumber}`;
        }
        return phonenumber;
    }
    return (
        <Link className={className} href={getUrl('feedByUser', { _user })}>
            {fullname}
        </Link>
    );
});

export const RoomLabel = memo(({ isPersistent, isMulticast, members, name, _sourceFeed, className }) => {
    if (!isPersistent && isMulticast && _sourceFeed) {
        return (
            <Link className={className} href={getUrl('feed', { _feed: _sourceFeed })}>
                {name}
            </Link>
        )
    }
    if (isPersistent) {
        return name;
    }
    return members
        .filter(m => !m.isMyUser)
        .map(m => (
            <UserLink
                className={className}
                key={m.id}
                fullname={m.fullname}
                _user={m._user}
                phonenumber={m.displayablePhonenumber || m.phonenumber}
            />
        ))
        .reduce((prev, curr) => [prev, curr && prev && ', ', curr], '');
});

export const getRoomLabelId = (isCaller, type, members) => {
    switch (type) {
        case NOTIFICATION_ROOM_TYPES.MISSEDBUTLIVE:
        case NOTIFICATION_ROOM_TYPES.MISSED:
            return 'call.callhistory.status.missedIncoming';
        case NOTIFICATION_ROOM_TYPES.HANGUP: {
            if (isCaller) {
                const isAnswered = members.some(m => !m.isMyUser
                    && m.status !== constants.rooms.USER_IN_ROOM_STATUS.WAITING
                    && m.status !== constants.rooms.USER_IN_ROOM_STATUS.MISSED);
                if (isAnswered) {
                    return 'call.callhistory.status.outgoing';
                }
                return 'call.callhistory.status.missedOutgoing';
            }
            return 'call.callhistory.status.incoming';
        }
        case NOTIFICATION_ROOM_TYPES.FORWARDEDAUTOMATICALLY: 
            return 'call.callhistory.status.forwardedAutomatically';
        case NOTIFICATION_ROOM_TYPES.FORWARDEDMANUALLY: 
            return 'call.callhistory.status.forwardedManually';
        case NOTIFICATION_ROOM_TYPES.INCOMING:
            return 'call.callhistory.status.callInProgress';
        case NOTIFICATION_ROOM_TYPES.OUTGOING:
        case NOTIFICATION_ROOM_TYPES.TAKEOFF:
        default:
            return 'call.callhistory.status.communicationInProgress';
    }
}

const mapStateToProps2 = (state, ownProps) => ({
    status: ownProps._user ? getStatusById(state, ownProps._user) : undefined,
    hasStatus: true
});

export const AvatarContainer = connect(mapStateToProps2, null)(AvatarStatus);

const MemberAvatar = ({ members }) => {
    if (members.length > 1) {
        return (
            <AvatarList
                mode='square'
                members={members}
                size="lg"
            />
        );
    }
    const {
        avatarUri,
        lastname,
        firstname,
        _user,
        phonenumber,
        isGuest,
    } = members?.[0] || {};
    return (
        <AvatarContainer
            _user={_user}
            src={avatarUri}
            alt={`${firstname} ${lastname}`}
            href={!_user || phonenumber || isGuest ? undefined : getUrl('profile', { _user })}
            size="lg"
            hasStatus={!phonenumber}
        />
    );
}

const Room = ({
    intl,
    createdAt,
    id: _room,
    isMulticast,
    isPersistent = false,
    name,
    isVideoCall,
    members,
    type,
    _sourceFeed,
    caller: { fullname, isMyUser }
}) => {
    const targetMember = members.find(m => !m.isMyUser);
    const {
        phonenumber,
        title,
    } = targetMember || {};
    const otherMembers = members.filter(m => !m.isMyUser);
    const translations = useMemo(() => ({
        label: intl.formatMessage({ id: 'global.button.recall' }),
        roomLabel: intl.formatMessage({ id: getRoomLabelId(isMyUser, type, members) }),
    }), [intl, isMyUser, type, members]);

    let meta;
    if (isPersistent || isMulticast) {
        meta = ` - ${fullname}`;
    } else if (title !== phonenumber && title) {
        meta = ` - ${title}`;
    }

    return (
        <NotificationLayout
            thumb={(
                <MemberAvatar
                    members={otherMembers}
                />
            )}
            category="room"
            date={createdAt}
            actions={[
                <RecallButton
                    key={_room}
                    color="primary"
                    buttonClassName="item"
                    isMulticast={isMulticast}
                    members={members}
                    isPersistent={isPersistent}
                    _room={_room}
                    _feed={_sourceFeed}
                >
                    {translations.label}
                </RecallButton>
            ]}
            className={classNames({
                missed: [NOTIFICATION_ROOM_TYPES.MISSED, NOTIFICATION_ROOM_TYPES.MISSEDBUTLIVE].includes(type)
            })}
        >
            <strong>
                <RoomLabel
                    isPersistent={isPersistent}
                    members={members}
                    name={name}
                    isMulticast={isMulticast}
                    _sourceFeed={_sourceFeed}
                />
            </strong>
            {meta}
            <div className="label">
                <Icon iconName={getIconName(isMyUser, type, isVideoCall, members)} />
                {translations.roomLabel}
            </div>
        </NotificationLayout>
    );
};

Room.propTypes = {
    intl: PropTypes.any.isRequired,
    isMulticast: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isPersistent: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    caller: PropTypes.shape({
        isMyUser: PropTypes.bool.isRequired,
    }).isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({
        avatarUri: PropTypes.string,
        title: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        _user: PropTypes.string,
        phonenumber: PropTypes.string,
        isGuest: PropTypes.bool,
    })).isRequired,
    type: PropTypes.oneOf(Object.values(NOTIFICATION_ROOM_TYPES)).isRequired,
};

const Component = memo(injectIntl(Room));

export default Component;

const mapStateToProps = (state) => ({
    _myUser: getCurrentUserIdSelector(state),
});

export const container = connect(mapStateToProps)(Component);
