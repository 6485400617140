import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { rgba, lighten } from 'polished';
import Link from 'components/Shared/Common/Link';
import Action from 'components/Shared/Common/Action';
import { colorLuminance } from 'utils/colors';
import { Badge } from 'antd';
import AvatarStatus from 'components/Shared/User/Avatar';

const pulseDot = keyframes`
    0%,
    25% {
        transform: scale(0);
    }
    30%,
    40%,
    85% {
        transform: scale(1);
    }
    35% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0);
    }
`;

const pulsarOpacity = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const GlobalStyle = createGlobalStyle`
    body, html{
        margin: 0;
        padding: 0;
    }
    .interface{
        display: flex;
        min-width: 0;
        min-height: 0;
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: white;
        font-family: 'Roboto', Helvetica, Sans-Serif;
        text-rendering: optimizeLegibility;
        font-weight:normal;
        margin:0;
        padding:0;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        font-variant-ligatures: common-ligatures;
        -moz-osx-font-smoothing: grayscale;
    }
    *:before,
    *:after,
    * {
        -ms-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    ul, li, ol{
        margin: 0;
        padding: 0;
    }
    input,textarea{
        font-family: "Roboto", "Helvetica Neue",Helvetica,Arial,sans-serif!important;
    }
    a,i, input, textarea, button, div, iframe, span, video {
        text-decoration:none;
        outline:0;
    }

    h1,h2,h3,h4,h5,h6,ul,li,p,span,a,button{
        margin:0;
        padding:0;
        text-decoration:none;
    }
    h1,h2,h3,h4,h5,h6,div,span,html,body{
        text-rendering: optimizeLegibility;
        font-smoothing: antialiased;
    }
    /* Align icon, label and arrow (eg: transfer button in room) */
    .ant{
        &-dropdown{
            &-menu{
                &-submenu-title{
                    display: flex;
                    align-items: center;
                    .ant-dropdown-menu-title-content{
                        flex: inherit !important;
                    }
                }
            }
        }
    }
    /* fix weird behavior with a plugin react/tour for popover position */
    .reactour__popover{
        >span{
            left: 10px!important;
        }
    }
    .s-dd-bookmark-action{
        .ant-dropdown{
            &-menu{
                &-item{
                    .ant-btn{
                        background-color: transparent !important;
                    }
                    &-hover{
                        background-color: transparent !important;
                    }
                }
            }
        }
    }
`;

export const StyledInterfaceHeader = styled.div`
    display: flex;
    padding: 15px 10px;
    box-shadow: 0 0 2px ${({theme}) => rgba(theme.black, 0.10)};
    flex-direction: row;
    align-items: center;
    line-height: normal;
    width: 100%;
    position: relative;
    z-index: 4;
    .ant-col{
        min-width: 0;
    }
    .separator{
        background-color: ${({theme}) => rgba(theme.black, 0.15)};
        width: 2px;
        height: 30px;
        transform: rotate(20deg);
        display: block;
    }
`;

export const StyledFeedHeaderText = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    min-width: 0;
    margin-right: 10px;
    .name,
    .subtitle{
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .name{
        color: ${({theme}) => theme.black};
        font-size: 14px;
        font-weight: 700;
    }
    .subtitle{
        color: ${({theme}) => rgba(theme.black, 0.6)};
        font-size: 14px;
    }
`;

export const StyledInterfaceActions = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 10px 10px;
    width: 100%;
    position: relative;
    align-items: flex-end;
`;

export const StyledInterfaceWrapper = styled.div`
    display: flex;
    flex: 1;
    align-self: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    min-width: 0;
    min-height: 0;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    position: relative;
`;

export const StyledInterface =  styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-self: flex-start;
    min-width: 0;
    min-height: 0;
    &.interface{
        flex-direction: row;
    }
    .content-channel-styled,
    .menu,
    &.interface .primary-nav .menu{
        overflow: auto;
        overflow: overlay;
        &::-webkit-scrollbar {
            width: 5px;
            &-track {
                border-radius: 4px;
                background: ${({theme}) => rgba(theme.white, 0.15)};
            }
            &-thumb {
                border-radius: 4px;
                background: ${({theme}) => rgba(theme.white, 0.35)};
                &:hover {
                    background: ${({theme}) => rgba(theme.white, 0.45)};
                }
            }
        }
    }
    .interface-content{
        display: flex;
        flex: 1;
        flex-shrink: 0;
        align-self: flex-start;
        min-height: 0;
        min-width: 0;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        position: relative;
        &-global{
            display: flex;
            flex: 1;
            align-self: flex-start;
            align-items: flex-start;
            min-width: 0;
            min-height: 0;
            flex-direction: row;
            flex-shrink: 0;
            width: 100%;
            &-left{
                display: flex;
                flex: 1;
                align-self: flex-start;
                align-items: flex-start;
                min-width: 0;
                min-height: 0;
                flex-direction: column;
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
    }
`;

export const StyledAvatarButton = styled.div`
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-shrink: 0;
    margin: 5px 0;
    min-height: 0;
    min-width: 0;
    padding: 5px;
    transition: all .25s ease;
    &:hover{
        background-color: ${({theme}) => rgba(theme.white, 0.1)};
        .text{
            color: ${({ theme }) => theme.white};
        }
    }
    .avatar{
        margin-right: 10px;
    }
`;

export const StyledStatusItem = styled(Action)`
    display: flex;
    flex: 1;
    flex-direction: column;
    .label{
        color: ${({theme}) => rgba(theme.black, .6)};
        font-size: 12px;
        white-space: break-spaces;
        max-width: 245px;
    }
    .line{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        &.active{
            .text{
                font-weight: bold;
            }
        }
        .icon{
            display: flex;
            margin-right: 8px;
            svg{
                color: ${({theme}) => theme.black};
                height: 12px;
                width: 12px;
            }
        }
        .text{
            color: ${({theme}) => theme.black};
            font-size: 14px;
            margin: 0 20px 0 0;
            white-space: normal;
            align-items: center;
            display: flex;
            flex: 1;
            flex-direction: row;
            flex-shrink: 0;
            min-height: 0;
            min-width: 0;
            transition: all .25s ease;
            .s-status{
                position: relative;
                margin-right: 10px;
                border: none;
                bottom: auto;
                flex-shrink: 0;
                height: 10px;
                left: auto;
                position: relative;
                right: auto;
                top: auto;
                width: 10px;
            }
        }
    }
`;

export const StyledDropdownItem =  styled.div`
    width: 100%;
    .item{
        color: inherit;
        display: block;
    }
`;

export const StyledGroupAvatar = styled(Link)`
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({theme}) => theme.white};
    background-color: ${({theme}) => theme.lighten};
    border-radius: 50%;
    line-height: 1;
    &.md{
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
    &.xl{
        width: 60px;
        height: 60px;
    }
    &.lg{
        width: 45px;
        height: 45px;
    }
    &.sm{
        width: 25px;
        height: 25px;
    }
    &.xs{
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
`;

export const StyledGroupAvatarLink = styled(StyledGroupAvatar)(Link);

export const StyledSimpleLink = styled(Action)`
    color: ${props => props.theme.mainColor2};
    font-size: 12px;
    margin-top: 5px;
    padding-bottom: 2px;
    border-bottom: 1px dashed ${props => props.theme.mainColor2};
    transition: all 250ms ease;
    position: relative;
    cursor: pointer;
    &:hover{
        &:after{
            width: 100%;
        }
    }
    input[type=file]{
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 2px;
        background-color: ${({theme}) => theme.success};
        transition: all 250ms ease;
    }
`;

export const StyledPageLayout = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-shrink: 0;
    width: 100%;
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    margin-right: -20px;
    min-width: 0;
    min-height: 0;
    &.ui-dashboard-page{
        background-color: #EEE;
    }
    &.fd-row{
        flex-direction: row;
    }
`;
export const StyledSearchFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0 auto 20px;
    max-width: 400px;
    width: 100%;
    ${StyledSimpleLink}{
        align-self: flex-end;
    }
`;

export const StyledBaseNotificationButton = styled(Action)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 250ms ease;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    &:hover{
        background-color: ${({ theme }) => rgba(theme.white, 0.10)};
        z-index: 5;
        .icon{
            opacity: 1;
        }
    }
    .icon{
        display: flex;
        opacity: 0.6;
        color: ${({theme}) => theme.white};
        transition: all 250ms ease;
        svg{
            height: 20px;
            width: 20px;
        }
    }
`;

export const StyledBellButton = styled(StyledBaseNotificationButton)`
    .s-icon-content{
        display: flex;
        align-items: center;
        justify-content: center;
        &.active{
            .icon-wrapper{
                .icon{
                    opacity: 1;
                    svg{
                        transform-origin: 50% 0;
                        animation: shake 1.5s ease infinite;
                    }
                }
                >span{
                    transform: scaleY(.9) scaleX(1);
                    transition: transform .07s ease .3s;
                    animation: removeLines .05s linear forwards .37s;
                }
            }
        }
        .icon-wrapper{
            >span{
                width: 6px;
                height: 2px;
                right: 110%;
                top: 50%;
                transform-origin: 100% 50%;
                transform: scaleY(.9) scaleX(0);
                display: block;
                position: absolute;
                background: ${({theme}) => theme.white};
            }
        }
    }
`;

export const StyledToggleSoundButton = styled(StyledBaseNotificationButton)`
    &.space-around{
        margin-right: 15px !important;
        margin-left: 5px !important;
    }
    .icon{
        margin-right: -2px;
        &.black{
            color: ${({theme}) => theme.black};
        }
    }
`;

export const StyledCategoryTitle = styled.div`
    margin-bottom: 20px;
    padding-bottom: 10px;
    position: relative;
    font-size: 24px;
    .s-title{
        margin: 0;
        transition: all 250ms ease;
        font-weight: 400;
    }
    &:after{
        background-color: ${props => props.theme.mainColor2};
        border-radius: 2px;
        bottom: 0;
        content: "";
        height: 4px;
        left: 0;
        position: absolute;
        width: 20px;
    }
`;

export const StyledExpandableSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    flex-shrink: 0;
    &.loading{
        .action{
            display: none;
        }
    }
    .placeholder{
        padding: 0 20px;
    }
    &:last-child{
        margin-bottom: 0;
    }
`;

export const StyledExpandableSectionTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 11px;
    color: ${({ theme }) => rgba(theme.fixedWhite || '#fff', .4)};
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 0 20px;
    position: relative;
    >svg{
        margin-left: 5px;
        width: 14px;
    }
    .action{
        position: absolute;
        top: 0;
        right: 20px;
        .btn{
            padding: 0;
        }
        &:hover{
            svg{
                color: ${({ theme }) => theme.fixedWhite || '#fff'};
            }
        }
        svg{
            cursor: pointer;
            transition: all 250ms ease;
            width: 18px;
            height: 18px;
            color: ${({ theme }) => rgba(theme.fixedWhite || '#fff', .4)};
        }
    }
`;

export const StyledExpandableListButton = styled.div`
    margin: 10px 20px 0px;
    color: ${({ theme }) => rgba(theme.fixedWhite, .6)};
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5px;
    background-color: ${({ theme }) => rgba(theme.fixedWhite, .1)};
    transition: all 250ms ease;
    align-self: flex-start;
    cursor: pointer;
    line-height: 1;
    padding: 5px 10px;
    border-radius: 30px;
    &.active{
        .icon{
            transform: rotate(-180deg);
        }
    }
    &:hover{
        color: ${({ theme }) => theme.fixedWhite};
        background-color: ${({ theme }) => rgba(theme.fixedWhite, .2)};
    }
    .icon{
        display: flex;
        align-items: center;
        transition: all 250ms ease;
        margin-left: 3px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
`;

export const StyledTransfertButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 250ms ease;
    &:empty{
        display: none;
    }
    &:before{
        content: "";
        top: 0;
        position: absolute;
        background-color: ${props => lighten(.2, colorLuminance(props.theme.mainColor1, 0.15))};
        height: 100%;
        width: 80px;
        right: -20px;
        transform: skew(-30deg);
        transition: all 250ms ease;
    }
    &:hover{
        &:before{
            background-color: ${props => lighten(.24, colorLuminance(props.theme.mainColor1, 0.15))};
        }
    }
    .ant-dropdown-trigger{
        position: relative;
        z-index: 1;
    }
    .icon{
        position: relative;
        color: ${({theme}) => theme.fixedWhite};
    }
`;

export const StyledExpandableSectionListItemLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    min-height: 0;
    min-width: 0;
    flex-shrink: 0;
    padding: 5px 0px 5px 20px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    >a{
        width: 100%;
    }
    .wrapper-content{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    &.active{
        .s-second-row{
            margin-top: 10px;
        }
        .wrapper-content{
            flex-direction: column;
            padding-right: 60px;
        }
        margin-right: -20px;
        border-top: 1px solid ${({ theme }) => rgba(theme.fixedWhite, .2)}; 
        background-color: ${props => lighten(.04, colorLuminance(props.theme.mainColor1, 0.15))};
        border-bottom: 1px solid ${({ theme }) => rgba(theme.fixedWhite, .2)}; 
        transition: all 250ms ease;
        .name{
            font-size: 16px;
            color: ${({theme}) => theme.fixedWhite};
            font-weight: bold;
            .content{
                color: ${({theme}) => theme.fixedWhite};
            }
        }
        &:hover{
            background-color: ${props => lighten(.08, colorLuminance(props.theme.mainColor1, 0.15))};
            .ant-avatar-group{
                .ant-avatar{
                    border-color: ${props => lighten(.08, colorLuminance(props.theme.mainColor1, 0.15))};
                }
            }
        }
    }
    &.unread{
        .name{
            font-weight: bold;
            color: ${({theme}) => theme.fixedWhite};
            .content{
                color: ${({theme}) => theme.fixedWhite};
                font-weight: bold;
            }
        }
    }
    .text{
        .name{
            color: ${({ theme }) => rgba(theme.fixedWhite, .6)};
            font-weight: 400;
        }
    }
    .small{
        width: 10px;
        height: 10px;
    }
    .name{
        color: ${({ theme }) => rgba(theme.fixedWhite, .6)};
        font-size: 14px;
        flex: 1;
        min-height: 0;
        min-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 0;
        position: relative;
        flex-direction: row;
        display: flex;
        align-items: center;
        .content{
            transition: all 250ms ease;
            transform: translateY(0);
            color: ${({ theme }) => rgba(theme.fixedWhite, .6)};
            align-items: center;
            flex-direction: row;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .ant-avatar-group{
        .ant-avatar{
            border-color: ${props => lighten(.04, colorLuminance(props.theme.mainColor1, 0.15))};
        }
    }
    .s-group-avatar,
    .s-btn-video{
        margin-right: 10px;
    }
`;

export const StyledExpandablePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px 5px;
    .name{
        width: 50px;
        margin-left: 20px;
    }
`;

export const StyledExpandableSectionListItemActions = styled(Action)`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    .visible,
    .hovered{
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
    .visible{
        transition: all 250ms ease;
        transform: translateY(0);
        margin-right: -20px;
    }
    .hovered{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-5px);
        transition: all 250ms ease;
    }

`;

export const StyledExpandableSectionListItemBadge = styled(Badge)`
    margin-left: 5px;
    width: 18px;
    height: 18px;
    .ant-badge-count{
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        &-sm{
            width: 18px;
            height: 18px;
            line-height: 18px;
            border-radius: 50%;
            padding: 0;
        }    
    }
`;

export const StyledAvatarStatus = styled(AvatarStatus)`
    margin-right: 10px;
    .s-status{
        border-color: ${props => colorLuminance(props.theme.mainColor1, 0.15)};
    }
`;

export const StyledExpandableSectionListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-right: 20px;
    transition: all 250ms ease;
    &:hover{
        cursor: pointer;
        background-color: ${props => lighten(.04, colorLuminance(props.theme.mainColor1, 0.15))};
        .s-list-actions{
            .visible{
                opacity: 0;
                visibility: hidden;
                transform: translateY(-5px);
            }
            .hovered{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
    &.active{
        background-color: ${props => lighten(.04, colorLuminance(props.theme.mainColor1, 0.15))};
        position: relative;
        .name{
            color: ${({ theme }) => theme.fixedWhite};
            font-weight: 400;
            .content{
                color: ${({ theme }) => theme.fixedWhite};
                font-weight: 400;
            }
        }
        span{
            &.border{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 2px;
            }
        }
    }
`;

export const StyledExpandableSectionList = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`;

export const StyledPulse = styled.div`
    background-color: ${({ theme }) => theme.roomPulseBullet};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 5px;
    .pulse-circle{
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        width: 4px;
        height: 4px;
        background-color: ${({ theme }) => theme.fixedWhite};
        border-radius: 50%;
        animation: ${pulseDot} 6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
    }
`;

export const StyledDashboardItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`;

export const StyledAvatar = styled.div`
    .avatar-container{
        position: relative;
    }
`;

export const StyledVoicePulseAvatar = styled.div`
    ${({ strengthRate }) => css`
        --strengthRate: ${strengthRate || 0};
    `}
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:after,
    &:before{
        background-color: #111;
        border-radius: 50%;
        content: "";
        display: block;
        height: 180%;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 180%;
        transform: translate(-50%, -50%);
    }
    &:after{
        background: ${({ theme }) => rgba(theme.fixedWhite, .15)};
        transform: translate(-50%, -50%) scale(calc(0.5 + calc(var(--strengthRate) * 0.0425)));
        transition: transform .2s steps(6,jump-none) 33.33ms;
    }
    &:before{
        background: transparent;
        border: 2px solid ${({ theme }) => rgba(theme.fixedWhite, .20)};
        opacity: 1;
        transform: translate(-50%, -50%) scale(.45);
    }
    &.start-pulsar{
        &:before{
            opacity: 0;
            transform: translate(-50%, -50%) scale(1);
            animation: ${pulsarOpacity} ${({ pulsarDuration }) => pulsarDuration}ms steps(42,jump-none);
            transition: transform ${({ pulsarDuration }) => pulsarDuration}ms steps(42,jump-none);
        }    
    }
`;

export const StyledBgApp = styled.div`
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('${props => props.backgroundImage || ''}');
    }
    .overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${props => rgba(props.backgroundColor, .90)};
    }
`;
