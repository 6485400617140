import { injectIntl, WrappedComponentProps } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse, CollapseProps, Row, Typography } from 'antd';
import styled from 'styled-components';

import FeedItemCRMNote from 'components/Shared/Feed/FeedItemCRMNote';

const { Title } = Typography;

const DataRow = styled(Row)`
  margin-bottom: 5px;
`;

const CustomCollapse = styled(Collapse)`
width: 100%;
`

export interface NoteData {
    id: string;
    title?: string;
    body?: string;
    createdAt: string;
    updatedAt: string;
    url: string;
}

type FeedItemCRMNotesProps = {
    notes?: NoteData[];
    onLoad?: () => void;
} & WrappedComponentProps;

const FeedItemCRMNotes = ({
    intl,
    notes = [],
    onLoad = () => {}
}: FeedItemCRMNotesProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const translate = useMemo(() => ({
        notes: intl.formatMessage({ id: 'feedItem.metas.crm.notes' })
    }), [intl]);

    const items: CollapseProps['items'] = useMemo(() => notes.map((note, id) => ({
        key: id,
        label: note?.title,
        children: <FeedItemCRMNote {...note} />
    })), [notes]);

    useEffect(() => {
        if (!isLoaded && onLoad) {
            setIsLoaded(true);
            onLoad?.();
        }
    }, [onLoad]);

    if (!notes || !notes.length) {
        return null;
    }

    return (
        <>
            <DataRow>
                <Title level={5}>{translate.notes} ({notes.length})</Title>
            </DataRow>
            <DataRow>
                <CustomCollapse size={'small'} expandIconPosition={'end'} items={items} />
            </DataRow>
        </>
    );
};

export default injectIntl(FeedItemCRMNotes as React.FunctionComponent<FeedItemCRMNotesProps>);