import React, { ReactElement, useMemo } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Button } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';
import { StyledPageLayout } from 'components/Shared/style';
import ThirdPartyConfigurationForm from 'components/Settings/ThirdPartyConfiguration/ThirdPartyConfigurationForm/container';
import { getQueryStringParameter, getUrl } from 'utils/url';

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: -20px;
`;

const ThirdPartyConfigurationPage = ({ intl }: WrappedComponentProps): ReactElement => {
    const id = getQueryStringParameter('id')(location.search);
    const translate = useMemo(() => ({
        goToSettings: intl.formatMessage({id: 'menuLeft.navigation.goBackToSettings'}),
        create: intl.formatMessage({id: 'thirdPartyConfigurations.title.create'}),
        edit: intl.formatMessage({id: 'thirdPartyConfigurations.title.edit'}),
    }), [ intl]);

    return (
        <StyledPageLayout className="ui-contact">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link href={getUrl('settings', {tabs: "third-party"})}>
                <StyledButton type="link">
                    <Icon size="xl" iconName="ChevronLeft" />
                    {translate.goToSettings}
                </StyledButton>
            </Link>
            <CategoryTitle
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label={id ? translate.edit : translate.create}
                icon={<AppstoreAddOutlined />}
            />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <ThirdPartyConfigurationForm id={id} />
        </StyledPageLayout>
    );
};

export default injectIntl(ThirdPartyConfigurationPage);
